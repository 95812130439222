import React, { ReactElement } from 'react'
import { getPage } from '@/themes'
import Template from '@/components/app/Template'
import { SearchPageProps } from '@/types/pages/search'

export default function ArticleSerps(props: SearchPageProps): ReactElement {
  const SerpsPage = getPage<SearchPageProps>(`ArticleSerps`)

  return (
    <Template {...props}>
      <SerpsPage {...props} />
    </Template>
  )
}
